import React from 'react';

function HomeMobileIntro() {
    return (
        <div id="homeMobileIntro">

            <div id="homeMobileIntroDescription">
                The world seems to have lost its order because we forget to give
                others space; nature seems to be protesting because we forget to
                be humble. Artists are risk takers and they dare to challenge the
                reality of our society.
                For things words can’t express, let pictures do the talking.
                For the warmth people can’t feel, maybe colours can ignite.
                To appreciate our artists here, time and space are all is required. <br></br><br></br>

                The galleries of your photos are open now. Galleries of all participating artists will be open starting August 28th.
            </div>

        </div>
    )
}

export default HomeMobileIntro;
